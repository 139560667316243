.organizationInfo {
  overflow-y: scroll;
  padding: 30px;
  position: relative;
  height: 100vh;
  flex: 1 0 auto;
  .organizationInfoBlock {
    margin-bottom: 40px;

  input {
    width: 100%;
    height: 40px;
    border: 1px solid #C4C4C4;
    box-sizing: border-box;
    border-radius: 4px;
    text-indent: 20px;
    outline: none;
  }
  }
  .organization__wrap {
    margin-bottom: 20px;
    background: #FFFFFF;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    width: 100%;
    min-height: 268px;
    padding: 20px;
    .small-title {
      margin-bottom: 20px;
    }
  }
  &__title {
    color: #000000;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 23px;
  }
  .organizationItem {
   display: inline-block;
    margin-right: 20px;
    img {
      width: 100px;
      height: 100px;
    }
    margin-bottom: 20px;
  }
}




.organization__footer {
  background-color: #fff;
  bottom: 0;
  width: 100%;
  left: 0;
  height: 150px;
  padding: 12px 30px;
  .MuiFormControl-root {
    width: 95%;
    margin-bottom: 16px;
  }
  .small-title {
    margin-bottom: 12px;
  }
}
.small-title {
  color: #000000;
  font-weight: bold;
  letter-spacing: -0.3px;
}







.button-group {
  display: flex;
  justify-content: center;
  .MuiButton-contained {
    box-shadow: none;
    margin-left: 15px;
    background: #50BF34;
    border-radius: 4px;
    color: #ffffff;
    text-transform: none;
    &:hover {
      background-color: darken(#50BF34, 15%);
    }
  }
  .MuiButton-outlined {
    text-transform: none;
    font-weight: bold;
    border: 1px solid #000
  }
  button {
    width: 132px;
  }
}
.MuiDialog-paperWidthSm {
  padding: 5px 20px;
  .button-group {
    margin-top: 15px;
  }
}
