.menuDrawer {
  height: 100vh;
  .MuiDrawer-paper {
    position: static;
    background-color: #fff;
    width: 324px;
    min-height: 100vh;
  }
  h2 {
    padding: 20px;
  }
  .MuiListItemText-root {
    > span {
      color: #000000;
      letter-spacing: -0.5px;
      font-size: 14px;
      font-weight: bold;
    }
  }
}

.active {
  .MuiListItem-button {
    background-color: #B9E5AE;
  }
}
