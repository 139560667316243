.authorization {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  h2 {
    margin-bottom: 42px;
  }
  .count {
    font-size: 12px;
    color: #cccccc;
  }
  &__logo {
    background: #FFFFFF;
    box-shadow: 0px 4.03125px 10.0781px rgba(0, 0, 0, 0.25);
    border-radius: 16.7969px;
    width: 85.33px;
    height: 86px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
  }
  &__form {
    text-align: center;
    .MuiFormControl-root {
      margin-top: 20px;
    }
    .MuiFormHelperText-root {
      text-align: center;
      padding: 5px 0;
      color: #43b826;
    }
  }
  &__block {
    background-color: #fff;
    padding: 40px;
    box-shadow: 0px 1px 25px rgba(0, 0, 0, 0.15);
    border-radius: 24px;
  }
  .btn {
    margin-top: 40px;
  }
}

