.header {
  background-color: #50BF34;
  color: #ffffff;
  padding: 20px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  width: 100%;
  h3{
    margin: 0;
    padding: 0;
  }
  &__wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    h3 {
      font-size: 16px;
    }
  }
  &__exit-wrap {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  &__exit {
    display: flex;
    align-items: center;
    margin-left: 30px;
    svg {
      margin-right: 10px;
    }
  }
  .line-wrap {
    display: flex;
    height: 100%;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      right: -15px;
      height: 100%;
      width: 1px;
      background-color: #fff;
    }
  }
}
