* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #E5E5E5;
  font-size: 14px;
  overflow-x: hidden;
}

.btn {
  background-color: #43B826;
  color: #ffffff;
  border: 0;
  width: 236px;
  height: 48px;
  margin: 0 auto;
  text-align: center;
  cursor: pointer;
  border-radius: 4px;
  &:hover {
    background-color: darken(#43B826, 5%);
  }
  &:focus {
    border: 0;
    outline: none
  }
}



.body {
  display: flex;
}
.content {
  width: 100%;
}
.Input {
  .MuiFormControl-root {
    width: 100%;
  }
}
.home {
  padding: 50px;
  h3 {
    font-size: 28px;
    margin-bottom: 15px;
  }
  span {
    font-size: 20px;
    color: #5c6b76;
  }
}
