.imageModal {
  .MuiDialog-paperWidthSm {
    max-width: 400px!important;
  }
  img {
    max-width: 100%;

  }
  position: relative;
  span {
    position: absolute;
    top: 15px;
    right: 35px;
  }
}
